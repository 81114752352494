<!--
 * @Description: 用户管理
 * @Date: 2019-12-23 11:41:57
 * @LastEditTime: 2021-10-26 18:59:14
 * @LastEditors: 肖槿
 -->
<template>
  <div id="user">
<!--    <p class="bread-crumbs">
      <a-icon v-if="isshowgoback" class="back-btn" type="rollback" @click="$router.go(-1)" />
      {{ route.name }} / {{ subRouteName }}
    </p>-->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'user',
  data() {
    return {
      isshowgoback: false,
      route: null,
      subRouteName: null
    }
  },
  created() {
    // 初次渲染面包屑
    this.renderNav()

    // 监听路由变化，修改顶部面包屑文字
    this.$watch('$route', to => {
      if(this.$route.path.split('/').length>4){
        this.isshowgoback=true;
      }else{
        this.isshowgoback=false;
      }
      this.subRouteName = to.name})
  },
  methods: {
    renderNav() {
      const route = this.$store.state.user.routes;
      this.route = route.find(item => item.path === '/admin').children.find(item => item.path === 'goods')
      const subPath = this.$route.path.split('/')[3]
      this.subRouteName = this.route.children.find(item => item.path === subPath).name
    }
  }
}
</script>

<style lang='less' scoped>
#user {
  background: #ffffff ;
  padding: 10px;
  .bread-crumbs {
    font-size: 12px;
    color: #666;
    padding: 8px 10px;
    background: #f0f0f0;
    margin-bottom: 10px;
  }
  .back-btn {
    padding: 2px;
    border: 1px solid #666;
    border-radius: 3px;
    margin-right: 10px;
  }
}
</style>
